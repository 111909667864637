import React, {useState} from "react"
import Button from "../components/Button/Button";
import Footer from "../components/Footer/Footer";
import classNames from "classnames";
import Resources, {UpgradeResourceAllocations} from "../components/Pricing/Resources";

const styles = require("../styles/pages/Pricing.module.scss");

const faqs = [
    {
        question: 'Do you offer a discount for annual subscriptions?',
        answer: 'At this time, we only offer monthly subscriptions.'
    },
    {
        question: 'Do you offer a discount for non-profits?',
        answer: 'Yes, we offer a 20% discount for eligible non-profit organizations. Please contact our support team to find out if you meet the criteria for our nonprofit discount.'
    },
    {
        question: 'What happens if I cancel?',
        answer: 'If you choose to cancel your subscription, then your account will be closed and all of your data will be deleted from our systems at the end of your billing cycle.'
    },
    {
        question: 'Can you charge me in my local currency?',
        answer: 'We currently only process subscription charges in USD.'
    },
    {
        question: 'What payment methods do you accept?',
        answer: 'We currently only accept payments via credit card.'
    },
]

export default function PricingPage() {
    const [openFaqs, setOpenFaqs] = useState<Array<number>>([]);
    const [selectedResources, setSelectedResources] = useState<UpgradeResourceAllocations>({
        jobs: {
            minimum: 100,
            selected: 100
        },
        users: {
            minimum: 1,
            selected: 1,
        }
    });

    function handleFaqClick(index: number) {
        if (openFaqs.includes(index)) {
            setOpenFaqs([
                ...openFaqs.filter((faqIndex) => faqIndex !== index)
            ])

            return;
        }

        setOpenFaqs([
            ...openFaqs,
            index
        ])
    }

    function updateResourceAmount(resourceType: any, amount: string) {
        let num = parseInt(amount);

        let resources = {...selectedResources};
        // @ts-ignore
        resources[resourceType].selected = `${num}`;

        setSelectedResources(resources);
    }

    return (
        <div className={styles.Pricing}>
            <div className={styles.Caclulator}>
                <div className={styles.PageTitle}>
                    <h1>Prestavi Pricing</h1>
                    <p>Usage based pricing with all features included in every plan.</p>
                </div>

                <Resources show={true} resources={selectedResources} updateResourceAmount={updateResourceAmount} />
            </div>


            <section className={styles.Faqs}>
                <h1>Frequently Asked Questions</h1>

                {
                    faqs.map((faq, index) => (
                        <div key={`faq-${index}`} onClick={() => handleFaqClick(index)} className={classNames([styles.Faq, openFaqs.includes(index) && styles.Selected])}>
                            <div className={styles.Question}>
                                <p>{faq.question}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.393" height="11.028" viewBox="0 0 18.393 11.028">
                                    <g transform="translate(528.419 -481.331) rotate(90)">
                                        <path d="M482.6,528.419a1.271,1.271,0,0,1-.891-2.175l7.518-7.407-7.467-6.589a1.27,1.27,0,1,1,1.68-1.9l8.488,7.489a1.268,1.268,0,0,1,.051,1.857l-8.488,8.363A1.264,1.264,0,0,1,482.6,528.419Z" fill="#5583ed"/>
                                    </g>
                                </svg>

                            </div>
                            {openFaqs.includes(index) && (
                                <div className={styles.Answer}>
                                    <p>{faq.answer}</p>
                                </div>
                            )}

                        </div>
                    ))
                }

            </section>

            <section className={styles.Questions}>
                <h1>Still have questions?</h1>
                <Button theme="solid" size="large" tone="light" onClick={() => window.location.href = '/contact-us'}>Get in touch</Button>
            </section>

            <Footer />
        </div>
    )
}

