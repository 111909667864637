import React, { CSSProperties, MutableRefObject } from "react";
import classNames from "classnames";

const styles = require("./Stepper.module.scss");

export interface StepperProps {
	label?: string;
	id: string;
	value: string;
	step?: number;
	placeholder?: string;
	className?: string;
	maxWidth?: number;
	min?: number;
	allowManualInput?: boolean;
	onChange: (value: number) => void;
	forwardRef?: MutableRefObject<HTMLInputElement | null>;
}

const Stepper: React.FunctionComponent<StepperProps> = ({
	label,
	id,
	value,
	step = 1,
	className,
	maxWidth,
	placeholder,
	forwardRef,
	allowManualInput = true,
	onChange,
	min = 1,
}) => {
	const inputClassNames = classNames([
		styles.Stepper,
		className,
		allowManualInput === false && styles.WithoutManualInput,
	]);

	const inlineStyles: CSSProperties = {};

	if (maxWidth) {
		inlineStyles.maxWidth = `${maxWidth}px`;
	}

	function handleChange(event: any) {
		const value = Number((event.target as HTMLInputElement).value);

		if (isNaN(value)) {
			return;
		}

		onChange(value);
	}

	function handleIncrease() {
		const nextNumber = parseInt(value) + step;

		onChange(nextNumber);
	}

	function handleDecrease() {
		const nextNumber = parseInt(value) - step;

		if (nextNumber < 0 || nextNumber < min) {
			return;
		}

		onChange(nextNumber);
	}

	return (
		<div className={inputClassNames}>
			{label !== undefined && <label htmlFor={`TextInput-${id}`}>{label}</label>}
			<div className={styles.Input}>
				<input
					id={`TextInput-${id}`}
					type={"text"}
					value={value}
					style={inlineStyles}
					ref={forwardRef}
					placeholder={placeholder}
					onChange={handleChange}
					readOnly={allowManualInput === false}
				/>
				<div className={styles.Buttons}>
					<div onClick={handleIncrease}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="11.445"
							height="6.994"
							viewBox="0 0 11.445 6.994"
						>
							<path
								d="M750.731,566.931l-5.006,5.564a.716.716,0,0,0,.532,1.194h10.011a.716.716,0,0,0,.532-1.194l-5.006-5.564A.716.716,0,0,0,750.731,566.931Z"
								transform="translate(-745.54 -566.695)"
								fill="#9cb7f7"
							/>
						</svg>
					</div>
					<div onClick={handleDecrease}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="11.445"
							height="6.994"
							viewBox="0 0 11.445 6.994"
						>
							<path
								d="M751.795,588.457l5.006-5.565a.715.715,0,0,0-.532-1.193H746.257a.715.715,0,0,0-.532,1.193l5.006,5.565A.716.716,0,0,0,751.795,588.457Z"
								transform="translate(-745.54 -581.699)"
								fill="#9cb7f7"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Stepper;
