// extracted by mini-css-extract-plugin
export var Amount = "Pricing-module--Amount--uWtm0";
export var Breakdown = "Pricing-module--Breakdown--HkfYk";
export var Calculation = "Pricing-module--Calculation--r4E6x";
export var Input = "Pricing-module--Input--ElaPd";
export var Interval = "Pricing-module--Interval--0o-2R";
export var Main = "Pricing-module--Main--YvFuh";
export var Pricing = "Pricing-module--Pricing--Lpo11";
export var Range = "Pricing-module--Range--MOA3j";
export var RangeAmounts = "Pricing-module--RangeAmounts--e30C4";
export var Resource = "Pricing-module--Resource--KfcMJ";
export var ResourceCalculation = "Pricing-module--ResourceCalculation--NCv3h";
export var ResourceSelection = "Pricing-module--ResourceSelection--PzuC-";
export var ResourceType = "Pricing-module--ResourceType--yyBuy";
export var Resources = "Pricing-module--Resources--Czu3z";
export var Slider = "Pricing-module--Slider--5FJTn";
export var Top = "Pricing-module--Top--5Ly9m";
export var Total = "Pricing-module--Total--JbAoo";