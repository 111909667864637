import React from "react";
import Stepper from "../Form/Stepper/Stepper";

const styles = require("./Pricing.module.scss");

export interface UpgradeResourceAllocations {
	jobs: {
		minimum: number;
		selected: number;
	};
	users: {
		minimum: number;
		selected: number;
	};
}

interface Props {
	show: boolean;
	resources: UpgradeResourceAllocations;
	updateResourceAmount: (resourceType: any, amount: string) => void;
}

const Resources: React.FunctionComponent<Props> = ({ show, resources, updateResourceAmount }) => {
	function calculateJobsPrice() {
		return (resources.jobs.selected / 100) * 10;
	}

	function calculateUsersPrice() {
		return resources.users.selected * 10;
	}

	function calculateTotalPrice() {
		const jobs = (resources.jobs.selected / 100) * 10;
		const users = resources.users.selected * 10;

		return jobs + users;
	}

	return (
		<div className={styles.Resources}>
			<div className={styles.Main}>
				<div className={styles.ResourceSelection}>
					<div className={styles.Allocations}>
						<div className={styles.ResourceType}>
							<h3>Jobs</h3>
							<div>
								<p>
									The number of jobs allocated to your plan resets every month. Jobs cost
									$10 per 100 per month.
								</p>
								<Stepper
									id="jobsAmount"
									step={100}
									min={100}
									allowManualInput={false}
									value={`${resources.jobs.selected}`}
									onChange={(value) => updateResourceAmount("jobs", `${value}`)}
								/>
							</div>

							<div className={styles.Range}>
								<input
									type="range"
									min="100"
									max="10000"
									value={resources.jobs.selected}
									onChange={(event) =>
										updateResourceAmount("jobs", (event.target as HTMLInputElement).value)
									}
									className={styles.Slider}
									id="jobRangeAmount"
									step={100}
								/>
								<div className={styles.RangeAmounts}>
									<span>100</span>
									<span>10,0000</span>
								</div>
							</div>
						</div>
						<div className={styles.ResourceType}>
							<h3>Users</h3>
							<div>
								<p>
									Choose the total users seats in your account. Each user costs $10/month.
								</p>
								<Stepper
									id="usersAmount"
									step={1}
									min={1}
									value={`${resources.users.selected}`}
									onChange={(value) => updateResourceAmount("users", `${value}`)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.Pricing}>
					<div className={styles.Total}>
						<span>$</span>
						<p>{calculateTotalPrice()}</p>
						<span>USD</span>
					</div>
					<div className={styles.Interval}>
						<span>billed monthly</span>
					</div>

					<div className={styles.Breakdown}>
						<h5>Price Breakdown</h5>

						<div className={styles.ResourceCalculation}>
							<div className={styles.Resource}>
								<div>
									<p>Jobs</p>
									<span>x{resources.jobs.selected}</span>
								</div>
								<div className={styles.Amount}>
									<p>${calculateJobsPrice()}</p>
								</div>
							</div>
							<div className={styles.Calculation}>
								<span>$10 / 100 jobs / month</span>
							</div>
						</div>

						<div className={styles.ResourceCalculation}>
							<div className={styles.Resource}>
								<div>
									<p>Users</p>
									<span>x{resources.users.selected}</span>
								</div>
								<div className={styles.Amount}>
									<p>${calculateUsersPrice()}</p>
								</div>
							</div>
							<div className={styles.Calculation}>
								<span>$10 / user / month</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Resources;
