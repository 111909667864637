// extracted by mini-css-extract-plugin
export var Amount = "Pricing-module--Amount--Q5aNS";
export var Answer = "Pricing-module--Answer--jnFxM";
export var Caclulator = "Pricing-module--Caclulator--rMykc";
export var Enterprise = "Pricing-module--Enterprise--RwU39";
export var ExtraResources = "Pricing-module--ExtraResources--ENmAL";
export var Faq = "Pricing-module--Faq--3gaTV";
export var Faqs = "Pricing-module--Faqs--3gRxH";
export var PageTitle = "Pricing-module--PageTitle--MrrIr";
export var Plan = "Pricing-module--Plan--pTG8e";
export var Plans = "Pricing-module--Plans--TJPpW";
export var Question = "Pricing-module--Question--pg78p";
export var Questions = "Pricing-module--Questions--WAMW2";
export var Resource = "Pricing-module--Resource--0r0xn";
export var Resources = "Pricing-module--Resources--tRuDm";
export var Selected = "Pricing-module--Selected--TSnOE";